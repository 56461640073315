import type { PromoCode } from "~/api_gen";

export const useCodesStore = defineStore("codesStore", () => {
  const isCodeFormRequired = ref(false);
  const isCodeChecking = ref(false);
  const currentCodeData = ref<PromoCode>();

  const checkCode = async (code: string) => {
    try {
      currentCodeData.value = undefined;
      isCodeChecking.value = true;
      const data = await useApi().promoCodes.codesShow({
        codeRequest: { code },
      });
      if (data) {
        currentCodeData.value = data;
      } else {
        currentCodeData.value = undefined;
      }
    } finally {
      isCodeChecking.value = false;
    }
  };

  const activateCode = async (code: string) => {
    return useApi().promoCodes.codesActivate({
      codeRequest: { code },
    });
  };

  const deactivateCode = async () => {
    return useApi().promoCodes.codesDeactivate();
  };

  return {
    isCodeFormRequired,
    isCodeChecking,
    currentCodeData,
    activateCode,
    deactivateCode,
    checkCode,
  };
});
